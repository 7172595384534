<template>
  <v-app>
    <component :user="user" :is="layout">
      <router-view :user="user" :layout.sync="layout" />
    </component>
  </v-app>
</template>

<script>
import * as fb from '@/firebase'
import './app.css'

export default {
  name: 'App',
  data() {
    return {
      layout: `div`,
      currentUser: null,
      user: null,
      isAdmin: false
    }
  },
  watch: {
    currentUser: {
      immediate: true,
      handler() {
        if (this.currentUser) {
          this.user = {
            isAdmin: this.isAdmin,
            id: this.currentUser.id,
            ...this.currentUser
          }
        }
      }
    }
  },
  async created() {
    let currentUser = fb.auth.currentUser
    if (currentUser) {
      fb.auth.currentUser
        .getIdTokenResult()
        .then(claims => {
          let userClaims = claims.claims
          this.isAdmin = userClaims.admin
        })
        .then(() => {
          if (currentUser.displayName == 'proxy') {
            this.$bind('currentUser', fb.db.doc(`Proxies/${currentUser.uid}`))
          } else if (currentUser.displayName == 'patient') {
            this.$bind('currentUser', fb.db.doc(`Patients/${currentUser.uid}`))
          } else if (currentUser.displayName == 'partner') {
            this.$bind('currentUser', fb.db.doc(`Partners/${currentUser.uid}`))
          }
        })
    }
  }
}
</script>
