import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const vptheme = process.env.VUE_APP_THEME_VP === 'true' ? true : false
var setTheme = new Vuetify()
if (vptheme) {
  setTheme = new Vuetify({
    icons: {
      iconfont: 'mdi' // default - only for display purposes
    },
    theme: {
      options: {
        customProperties: true
      },
      light: true,
      themes: {
        light: {
          primary: '#0082D2',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          error: '#D11516',
          red: '#D11516',
          background: '#F6F8FB',
          titles: '#ffffff',
          bgGradiant1: '#0082D2',
          bgGradient2: '#ffffff'
        }
      }
    }
  })
} else {
  setTheme = new Vuetify({
    icons: {
      iconfont: 'mdi' // default - only for display purposes
    },
    theme: {
      options: {
        customProperties: true
      },
      light: true,
      themes: {
        light: {
          primary: '#ff3e3a',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          error: '#da2818',
          red: '#da2818',
          background: '#F6F8FB',
          titles: '#000000',
          bgGradiant1: '#ffffff',
          bgGradient2: '#ffffff'
        }
      }
    }
  })
}

export default setTheme
