import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebase'
import firebase from 'firebase/app'

Vue.use(VueRouter)
var userClaims = {}

const routes = [
  {
    path: '/login',
    name: 'login',

    component: () => import('../views/login/Login.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next('/appointments')
      } else if (
        auth.currentUser &&
        auth.currentUser.displayName === 'patient'
      ) {
        next('/my-appointments')
      } else {
        next()
      }
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/Admin.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login')
      } else {
        await firebase.getCurrentUser().then(user => {
          if (user) {
            user.getIdTokenResult().then(claims => {
              userClaims = claims.claims
            })
          }
        })

        if (userClaims.admin) {
          next()
        } else {
          next('/login')
        }
      }
    }
  },
  {
    path: '/healthcareprovidersignup',
    name: 'healthcareprovidersignup',

    component: () => import('../views/login/Signup.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next('/appointments')
      } else if (
        auth.currentUser &&
        auth.currentUser.displayName === 'patient'
      ) {
        next('/my-appointments')
      } else {
        next()
      }
    }
  },

  {
    path: '/verify',
    name: 'Verify',
    component: () => import('../views/Verify.vue')
  },
  {
    path: '/thankyou',
    name: 'Thankyou',
    component: () => import('../views/login/ThankYou.vue')
  },
  {
    path: '/register',
    name: 'register',

    component: () => import('../views/login/Register.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next('/appointments')
      } else if (
        auth.currentUser &&
        auth.currentUser.displayName === 'patient'
      ) {
        next('/my-appointments')
      } else {
        next()
      }
    }
  },
  {
    path: '/complete-registration',
    name: 'CompleteRegistration',
    component: () => import('@/views/CompleteRegistration.vue')
  },
  {
    path: '/setpassword',
    name: 'SetPassword',
    component: () => import('@/views/setPassword.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login')
      } else {
        next()
      }
    }
  },
  {
    path: '/patientregistration',
    name: 'patientSignUp',

    component: () => import('../patientViews/specialRoutes/patientSignUp.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next('/appointments')
      } else if (
        auth.currentUser &&
        auth.currentUser.displayName === 'patient'
      ) {
        next('/my-appointments')
      } else {
        next()
      }
    }
  },

  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Homepage.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next('/appointments')
      } else if (
        auth.currentUser &&
        auth.currentUser.displayName === 'patient'
      ) {
        next('/my-appointments')
      } else {
        next()
      }
    }
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    beforeEnter(to, from, next) {
      next()
    }
  },
  {
    path: '/appointments',
    name: 'Appointments',

    component: () => import('../views/Appointments.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/proxies',
    name: 'Proxies',

    component: () => import('../views/Proxies.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/patients',
    name: 'Patients',

    component: () => import('../views/Patients.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/settings',
    name: 'Settings',

    component: () => import('../views/Settings.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/appointment',
    name: 'past-appointment',
    component: () => import('../views/PastAppointment.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/user/settings',
    name: 'UserSettings',

    component: () => import('../views/UserSettings.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/upload',
    name: 'UploadPatients',

    component: () => import('../views/UploadPatients.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/room',
    name: 'Room',
    component: () => import('../components/room/Room.vue')
  },

  {
    path: '/waiting-room',
    name: 'DocWaitingRoom',

    component: () => import('../views/DocWaitingRoom.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/my-appointments',
    name: 'MyAppointments',

    component: () => import('../patientViews/MyAppointments.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'patient') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/my-exam',
    name: 'MyExam',

    component: () => import('../patientViews/MyExam.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'patient') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/my-settings',
    name: 'MySettings',

    component: () => import('../patientViews/Settings.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'patient') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/partner/appointments',
    name: 'Upcoming',

    component: () => import('@/partnerViews/Upcoming.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'partner') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/partner/settings',
    name: 'PartnerSettings',

    component: () => import('@/partnerViews/Settings.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'partner') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/NotFound.vue')
  },
  {
    path: '/patientpdf/:practiceId/:uniqueName/:patientId',
    name: 'PatientPdf',
    component: () => import('@/components/PatientPdf.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/order/:orderId',
    name: 'Order',
    component: () => import('@/views/Order.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next()
      } else if (
        auth.currentUser &&
        auth.currentUser.displayName === 'partner'
      ) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/:doctorLink',
    name: 'Doctor',
    component: () => import('../views/Doctor.vue'),
    beforeEnter(to, from, next) {
      if (auth.currentUser && auth.currentUser.displayName === 'proxy') {
        next('/appointments')
      } else if (
        auth.currentUser &&
        auth.currentUser.displayName === 'partner'
      ) {
        next('/partner/appointments')
      } else {
        next()
      }
    }
  }
  // {
  //   path: '/:doctorLink/login',
  //   name: 'WaitingRoomLogin',
  //   component: () =>
  //     import('../patientViews/specialRoutes/waitingRoomLogin.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
