import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyB1ptu9wvi1fmjvlbBaYLRseBbAC829wms',
  authDomain: 'vpexam-hhvi-dhp.firebaseapp.com',
  databaseURL: 'https://vpexam-hhvi-dhp.firebaseio.com',
  projectId: 'vpexam-hhvi-dhp',
  storageBucket: 'vpexam-hhvi-dhp.appspot.com',
  messagingSenderId: '861634607147',
  appId: '1:861634607147:web:4a7d038b971dcc15b2d26e',
  measurementId: 'G-DKJFVXHYEW'
}

firebase.initializeApp(firebaseConfig)
firebase.firestore().settings({ experimentalForceLongPolling: true })

export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
export const analytics = firebase.analytics()
export const functions = firebase.functions()

export const proxiesCollection = db.collection('Proxies')
export const practicesCollection = db.collection('Practices')
export const patientsCollection = db.collection('Patients')
export const invitesCollection = db.collection('Invite_Reqs')
export const examComponentsCollection = db.collection('Exam_Components')
