import VueMq from 'vue-mq'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueCompositionApi from '@vue/composition-api'
import { firestorePlugin } from 'vuefire'
import CKEditor from '@ckeditor/ckeditor5-vue'
import html2pdf from 'html2pdf.js'
import draggable from 'vuedraggable'
import AudioVisual from 'vue-audio-visual'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import { auth } from './firebase'
import VueWaveSurfer from 'vue-wave-surfer'
import VueMeta from 'vue-meta'
import VueSignaturePad from 'vue-signature-pad'

Vue.use(VueMeta)
Vue.use(VueSignaturePad)
Vue.use(VueWaveSurfer)
Vue.use(CKEditor)
Vue.use(VueCompositionApi)
Vue.use(firestorePlugin)
Vue.use(html2pdf)
Vue.use(draggable)
Vue.use(AudioVisual)
Vue.use(VuePlyr)

Vue.config.productionTip = false

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    tabletlg: 1263,
    laptop: 1250,
    desktop: Infinity
  }
})

export const eventBus = new Vue()

var debounceTimeout
const DebounceDueTime = 1000 // 200ms

auth.onAuthStateChanged(() => {
  if (debounceTimeout) clearTimeout(debounceTimeout)

  debounceTimeout = setTimeout(() => {
    debounceTimeout = null

    new Vue({
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }, DebounceDueTime)
})

// auth.onAuthStateChanged(() => {
//   new Vue({
//     router,
//     vuetify,
//     render: h => h(App)
//   }).$mount('#app')
// })
